<template>
  <div class="checkoutPage">    
    <h1 class="bx--col" style="margin-bottom:0.5rem;">Riepilogo ordine</h1>
    <h5 style="text-align: right; margin-right:15px; margin-bottom:10px;" v-if="this.$store.state.cart_qty != 0">Totale ordine: {{ priceToCurrency(cart_total) }}</h5>
    <h5 style="text-align: right; margin-right:15px; margin-bottom:10px;" v-if="this.$store.state.cart_qty != 0">Totale IVA inclusa: {{ priceToCurrency(cart_total * $store.state.settings.tax) }} </h5>
    <p
      class="bx--col bx--type-expressive-paragraph-01"
      v-if="this.$store.state.cart_qty == 0"
    >
      Il tuo carrello è vuoto!
    </p>
    <shopping-cart-list v-else />
    <cv-button-set>
      <router-link to="/" custom v-slot="{ navigate }">
        <cv-button kind="secondary" size="lg" @click="navigate"
          >Indietro</cv-button
        >
      </router-link>
      <router-link to="/confirm-order" custom v-slot="{ navigate }">
        <cv-button
          :disabled="$store.state.cart_qty == 0"
          @click="navigate"
          size="lg"
          >Avanti</cv-button
        >
      </router-link>
    </cv-button-set>
  </div>
</template>

<script>
import ShoppingCartList from './ShoppingCartList';

export default {
  name: 'CheckoutPage',
  data() {
    return {};
  },
  components: {
    ShoppingCartList,
  },
  mounted() {},
  computed: {
    cart_total(){
       var $this = this;
       let total = 0;
       Object.keys($this.$store.state.items).forEach(function(elem){
            Object.entries($this.$store.state.items[elem]).forEach(function(val,k){
                let item = {};
                item = val[1];
                if ('total' in item)
                  total += item.total;
            });
       });
        return total;
    }
  },
  methods: {
     priceToCurrency(price){
        return Intl.NumberFormat('it-IT', {style: 'currency', currency: this.$store.state.settings.currency}).format(price);
    },
  },
};
</script>

<style lang="scss">
@use 'carbon-components/scss/globals/scss/spacing' as s;

h1 { margin: s.$spacing-07 0; }

.checkoutPage {
  overflow: hidden;

  div.empty-cart-msg {
    margin-top: 20px;
    padding: 30px;
    text-align: center;
  }

  .cv-button-set {
    position: absolute;
    width: 100%;
    bottom: 0;

    .cv-button {
      max-width: none;
      flex: 0 1 50%;
    }
  }
}
</style>
