<template>
     <cv-structured-list :condensed="true" class="shoppingCartList">
    <template slot="items">
      <cv-structured-list-item
        v-for="(row, rowIndex) in rows"
        :key="`${rowIndex}`"
      >
        <cv-structured-list-data>
          <h4 class="category">{{ $store.state.settings.packaging }} {{ $store.state.settings.qty_per_box }} {{ row.cat }}</h4>

          <h2>{{ row.descr }}</h2>

          <p><span class="text-uppercase">{{ row.brand }}</span><span style="text-align:right">{{ priceToCurrency(row.total) }} + IVA</span></p>
          <cv-row kind="condensed">
            <cv-column>
              <qtItemselector
                :index="rowIndex"
                :item="row"
                @incrementQty="incrementQty(row.cat,rowIndex)"
                @decrementQty="decrementQty(row.cat,rowIndex)"
                @updateQty="updateQty"
              />
            </cv-column>
            <cv-column>
              <cv-button @click="removeCartItem(row.cat,rowIndex)" kind="tertiary"
                >Rimuovi
               </cv-button>
            </cv-column>
          </cv-row>
        </cv-structured-list-data>
      </cv-structured-list-item>
    </template>
  </cv-structured-list>
</template>

<script>
import ItemQtySelector from '../ItemQtySelector/ItemQtySelector';

export default {
  name: 'ShoppingCartList',
  components: { qtItemselector: ItemQtySelector },
  props: {},
  data() {
    return {
    };
  },
  mounted() {},
  computed: {
    rows: function(){
       var $this = this;
       let items = {};
       Object.keys($this.$store.state.items).forEach(function(elem){
            Object.entries($this.$store.state.items[elem]).forEach(function(val,k){
                let item = Object.assign(val[1],{});
                if ('cart_qty' in item)
                  items[val[0]] = item;
            });
       });
       return items;
    }
  },
  methods: {
    close() {},
    incrementQty(cat,idx) {
      this.$store.commit('incrementQty', {cat: cat, index: idx});
    },
    decrementQty(cat,idx) {
      this.$store.commit('decrementQty', {cat: cat, index: idx});
    },
    updateQty(data) {
      this.$store.commit('setQty', data);
    },
    removeCartItem(cat,idx) {
      this.$store.commit('removeCartItem', {cat: cat, index: idx});
    },
    priceToCurrency(price){
        return Intl.NumberFormat('it-IT', {style: 'currency', currency: this.$store.state.settings.currency}).format(price);
    },
  },
};
</script>

<style lang="scss">
@use 'carbon-components/scss/globals/scss/spacing' as s;

.shoppingCartList {
  p {
    margin-bottom: s.$spacing-05;
  }

  .cv-structured-list-data > .cv-row > .cv-column:last-child {
    text-align: right;
    padding-right: s.$spacing-06;
  }

}
</style>
